<template>
  <div style="margin-top: 10px">
    <div style="background-color: white; padding: 10px">
      <!--收货地址-->
      <div
        style="padding: 10px; margin-bottom: 20px; border-bottom: 1px solid #eee"
      >
        <div
          style="font-size: 20px; border-bottom: 2px solid dodgerblue; padding-bottom: 10px; margin-bottom: 20px"
        >
          确认订单
        </div>
        <!-- <div style="padding: 10px 0; color: #555" v-for="item in addressData" :key="item.id">
          <el-radio v-model="chooseAddress" :label="item" border>{{ item.linkUser }}</el-radio>
          <span>{{ item.linkUser }}</span>
          <span style="margin-left: 20px">{{ item.linkPhone }}</span>
          <span style="margin-left: 20px">{{ item.linkAddress }}</span>
        </div> -->
      </div>

      <!--        商品确认-->
      <el-table :data="carts" stripe style="width: 100%" :show-header="false">
        <el-table-column label="商品图片" width="150">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.goods.imgs[0]"
              style="width: 100px; height: 100px;"
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="goods.name" label="商品名称"></el-table-column>
        <el-table-column label="价格">
          <template slot-scope="scope">
            <span
              v-html="scope.row.goods.realPrice + ' X ' + scope.row.count"
            ></span>
          </template>
        </el-table-column>
      </el-table>
      <div style="width: 40%; margin-top: 20px;">
        <el-form ref="form"  label-width="80px">
          <el-form-item label="昵称:">
            <el-input v-model="linkUser" placeholder="例：金先生"></el-input>
          </el-form-item>
          <el-form-item label="联系方式:">
            <el-input
              v-model="linkPhone"
              placeholder="请输入您的电话"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div style="margin-top: 10px">
        <div style="background-color: white; padding: 10px">
          <div style="color: red; text-align: right">
            <div>
              <span>总价：</span>
              <span>￥ {{ totalPrice }}</span>
            </div>
            <!-- <div style="text-align: right; color: #999; font-size: 12px; padding: 5px 0">
              运费： ￥ 0
            </div> -->
            <div style="text-align: right; color: #999; font-size: 12px;">
              优惠： - ￥{{ discount }}
            </div>
            <div style="padding: 10px 0">
              <el-button
                style="background-color: red; color: white; width: 100px"
                @click="submitOrder"
                >提交订单</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <el-dialog title="付款" :visible.sync="dialogTableVisible">
        <div id="qrCode" ref="qrCodeDiv"></div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisible = false"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import API from "@/utils/request";
import API2 from "@/utils/request2";
import QRCode from "qrcodejs2";
export default {
  name: "cart",
  data() {
    return {
      chooseAddress: null,
      dialogTableVisible: false,
      user: {},
      addressData: [],
      carts: [],
      totalPrice: 0,
      discount: 0,
      linkPhone: "",
      linkUser: "",
    };
  },
  created() {
    // this.user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}
    // if (!this.user) {
    //   this.$message({
    //     type: "warning",
    //     message: '请登录！'
    //   })
    //   return
    // }
    this.load();
    // this.bindQRCode();
  },
  methods: {
    bindQRCode() {
      this.$nextTick(() => {
        document.getElementById("qrCode").innerHTML = "";
        console.log("显示二维码的元素(div)=" + this.$refs.qrCodeDiv);
        this.qrcode = new QRCode(this.$refs.qrCodeDiv, {
          // text: 'https://www.guizhongjie.com/a/?id=' + this.id,
          text:
            "http://zf.miaofu8.com:80/player/qrcode?random=4mhj8gpxvttosrffpn6j",
          width: 150,
          height: 150,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
        });
      });
    },

    submitOrder() {
      console.log(this.carts);
      
      console.log(this.chooseAddress);
      if (!this.linkPhone) {
        this.$message({
          type: "warning",
          message: "请留下联系方式！",
        });
        return;
      }

      // 提交订单
      API.post("/api/order", {
        totalPrice: this.totalPrice,
        linkUser: this.linkUser,
        linkPhone: this.linkPhone,
        linkAddress: "虚拟订单",
        carts: JSON.stringify(this.carts),
        type: this.$store.state.type,
      }).then((res) => {
        if (res.code === "0") {
          this.$message({
            type: "success",
            message: "提交成功,正在跳转支付！",
          });

          // this.$router.replace("/front/order");
          API2.post("/api/payDemo/createOrder", {
            partyType: 17,
            payType: 6,
            goodsNum:1,
            money: this.carts[0].goods.realPrice,
          }).then((res) => {
            console.log('zhifu',res);
            
            if (res.code == 200) {
              window.open(res.data.payUrl);
            } else {
              setTimeout(() => {
                this.$message({
                  offset: 50,
                  message: "订单创建异常",
                  type: "warning",
                });
              }, 200);
            }
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    load() {
      this.carts = this.$store.state.carts;

      API.post("/api/order/pre", { carts: JSON.stringify(this.carts) }).then(
        (res) => {
          this.carts = res.data.list;
          this.totalPrice = res.data.totalPrice;
          this.discount = res.data.discount;

          // 处理图片
          this.carts.forEach((item) => {
            console.log(item);
            if (!item.goods.imgs) {
              item.goods.imgs = [""];
            } else {
              item.goods.imgs = JSON.parse(item.goods.imgs);
            }
          });
        }
      );

      // 获取收货地址
      API.get("/api/address").then((res) => {
        this.addressData = res.data;
      });
    },
  },
};
</script>

<style scoped></style>
